import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex, Box, Heading, Text } from 'rebass';

import Pulse from 'react-reveal/Pulse';
import Tada from 'react-reveal/Tada';
import Flip from 'react-reveal/Flip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import StepCard from '../../components/talent/StepCard';

const CircularWaves = ({ size, opacity, delay, children }) => (
  <Flex
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
    <Pulse count={2} delay={delay}>
      <Flex
        style={{
          borderRadius: '100%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          opacity: `${opacity}`,
        }}
        bg='#66C787'
        width={size}
        height={size}
        justifyContent='center'
        alignItems='center'>
        {children}
      </Flex>
    </Pulse>
  </Flex>
);

const Step2 = ({ images }) => (
  <Box id='step2' py='50px' my='100px' bg='white'>
    <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap' py={2}>
      <Box maxWidth='715px' textAlign='center'>
        <Flex justifyContent='center'>
          <Text fontSize='20px' fontWeight='500' mr={3} color='secondaryDark'>
            STEP 2
          </Text>
          <Text fontSize='20px' color='greyLight5'>
            20 min
          </Text>
        </Flex>

        <Heading mt={3} mx={[0, 5, 6]} fontSize={['36px', '40px']} fontWeight='500' as='h2'>
          Have a short call with a Nexton Career Coach
        </Heading>
        <StepCard maxWidth='568px'>
          <Flex justifyContent='space-between' flexWrap='wrap'>
            <Flex flexDirection='column' alignItems='center' width={[3 / 8]}>
              <Box width='110px'>
                <GatsbyImage image={images['latin-circle-110']} alt='talent pic' />
              </Box>
              <Text fontSize='22px' fontWeight='500' letterSpacing='-0.63px' mt={3} mb={2}>
                José Zamora
              </Text>
              <Text fontSize='18px' color='greyLight6' letterSpacing='-0.5px' mb='12px'>
                Full Stack Dev
              </Text>
            </Flex>
            <Flex flexDirection='column' alignItems='center' width={[2 / 8]}>
              <Box style={{ position: 'relative', width: '100%', height: '110px', zIndex: '1' }}>
                <CircularWaves size='90px' opacity='0.1' delay={1150} />
                <CircularWaves size='66px' opacity='0.3' delay={1000} />
                <CircularWaves size='50px' opacity='1' delay={850} />
                <CircularWaves size='30px' opacity='1' delay={4999}>
                  <Tada count={2} delay={600}>
                    <Text fontSize='20px' color='white'>
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </Text>
                  </Tada>
                </CircularWaves>
              </Box>
            </Flex>
            <Flex flexDirection='column' alignItems='center' width={[3 / 8]}>
              <Box width='110px'>
                <GatsbyImage image={images['agus-circle']} alt='agustin picture' />
              </Box>
              <Text fontSize='22px' fontWeight='500' letterSpacing='-0.63px' mt={3} mb={2}>
                Agustín Di Luciano
              </Text>
              <Text fontSize='18px' color='greyLight6' letterSpacing='-0.5px' mb='12px'>
                Career Coach
              </Text>
            </Flex>
          </Flex>
          <Flip top delay={2000}>
            <Flex
              style={{
                position: 'absolute',
                bottom: '-48px',
                left: 'calc(50% - 61px)',
                borderRadius: '8px',
              }}
              bg='#B9E9F3'
              width='122px'
              height='36px'
              justifyContent='center'
              alignItems='center'>
              <Text fontSize='20px' color='secondary' mr='10px'>
                <FontAwesomeIcon icon={faClock} />
              </Text>
              <Text fontSize='20px' color='secondary'>
                20 min
              </Text>
            </Flex>
          </Flip>
        </StepCard>
        <Text fontSize='20px' color='greyLight8'>
          <span style={{ fontWeight: '500' }}>
            Our expert engineers will dive into your interests and the market’s needs
          </span>{' '}
          in order to find the ideal job that fits your career goals and salary expectations
        </Text>
      </Box>
    </Flex>
  </Box>
);

export default Step2;
