import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Flex, Box, Heading, Text } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import Flash from 'react-reveal/Flash';
import Pulse from 'react-reveal/Pulse';
import LoadableStyledXarrow from '../../components/talent/LoadableStyledXarrow';

const XarrowPoint = styled.div`
  position: absolute;
`;

const OptionsImage = ({ id, img }) => (
  <Flex justifyContent='center' alignItems='center'>
    <Flex width={['105px', '150px']}>
      <GatsbyImage
        image={img}
        alt='option'
        style={{
          borderRadius: '100%',
          zIndex: '1',
          width: '100%',
        }}
      />
    </Flex>
    <XarrowPoint id={id} />
  </Flex>
);

const Step3 = ({ images }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });

  return (
    <Box id='step3' my='100px' bg='white'>
      <Flex alignItems='center' flexDirection='column'>
        <Flex justifyContent='center' alignItems='center'>
          <Box width={['150px', '205px']} mb={5}>
            <GatsbyImage image={images['latin-circle-205']} alt='talent pic' style={{ zIndex: '1' }} />
          </Box>
          <XarrowPoint id='choose' />
        </Flex>
        <Box
          maxWidth='540px'
          textAlign='center'
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            boxShadow: '0px 0px 35px 20px rgba(255,255,255,0.6)',
            zIndex: 1,
          }}>
          <Flex justifyContent='center'>
            <Text fontSize='20px' fontWeight='500' color='secondaryDark'>
              STEP 3
            </Text>
          </Flex>

          <Heading mt={3} fontSize={['36px', '40px']} fontWeight='500' as='h2'>
            Meet CTO's from purposeful tech companies!
          </Heading>

          <Text fontSize='20px' mt='24px' color='greyLight8'>
            We focus on long term jobs for exciting, innovative companies in the US. We will introduce you to companies
            that fits your skills and preferences.
          </Text>
        </Box>
        <Flex width='100%' maxWidth='1100px' mt='75px' justifyContent='space-between' style={{ zIndex: '1' }}>
          <Pulse delay={1050}>
            <OptionsImage id='satellite' img={images.satellite} />
          </Pulse>
          <Pulse delay={1150}>
            <OptionsImage id='medicine' img={images.medicine} />
          </Pulse>
          <Pulse delay={1150}>
            <OptionsImage id='bitcoin' img={images.bitcoin} />
          </Pulse>
          {isDesktopOrLaptop && (
            <Pulse delay={1350}>
              <OptionsImage id='commerce' img={images.commerce} />
            </Pulse>
          )}
          {isDesktopOrLaptop && (
            <Pulse delay={1450}>
              <OptionsImage id='mobile' img={images.mobile} />
            </Pulse>
          )}
        </Flex>
      </Flex>

      <Flash delay={650}>
        <>
          <LoadableStyledXarrow start='choose' end='satellite' />
          <LoadableStyledXarrow start='choose' end='medicine' />
          <LoadableStyledXarrow start='choose' end='bitcoin' />
          {isDesktopOrLaptop && <LoadableStyledXarrow start='choose' end='commerce' />}
          {isDesktopOrLaptop && <LoadableStyledXarrow start='choose' end='mobile' />}
        </>
      </Flash>
    </Box>
  );
};

export default Step3;
