import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { Link } from 'rebass';

const StarRating = ({ titleLogo, value, link }) => {
  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      const rawPercentage = i <= value ? 100 : (i - value) * 100;
      const ponderatedPercentage = rawPercentage < 100 ? 100 - rawPercentage : rawPercentage;
      const style = { width: `${Math.max(0, ponderatedPercentage)}%` };
      stars.push(
        <div key={i} className='star my-auto'>
          <FontAwesomeIcon icon={faStar} className='empty-star' />
          <div className='filled-star' style={style}>
            <FontAwesomeIcon icon={faStar} className='full-star' />
          </div>
        </div>,
      );
    }
    return stars;
  };

  return (
    <Link href={link} target='_blank' className='d-flex mx-auto' rel='noopener noreferrer'>
      <div className='starRating my-auto mx-auto'>
        <img className='mx-auto logo-image' src={titleLogo} alt='Star rating logo' />
        {renderStars()}
        <span className='title score my-auto'>{value}</span>
      </div>
    </Link>
  );
};

export default StarRating;
