import React from 'react';
import { Flex, Text } from 'rebass';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

const IncRanking = () => {
  const { incRanking } = useStaticQuery(graphql`
    {
      incRanking: allFile(filter: { relativeDirectory: { eq: "images/IncRanking" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);

  const incRankingLogo = getImage(incRanking.nodes[0]);

  return (
    <Flex
      style={{
        border: '1px solid #D0D0D0',
        margin: '0',
        padding: '0',
        borderRadius: '20px',
      }}
      width='fit-content'
      flexWrap='no-wrap'>
      <GatsbyImage
        onClick={() => {
          window.open('https://www.inc.com/profile/nexton');
        }}
        className='incRankingImage'
        image={incRankingLogo}
        alt='IncRanking Logo'
      />
      <Flex flexDirection='column' justifyContent='start' alignItems='start' className='incRankingTextContainer'>
        <Text className='incRankingTitle' fontWeight='400' color='nextonDarkGrey'>
          Fastest- Growing U.S. Company{' '}
        </Text>
        <Text className='incRankingText' fontWeight='400' color='nextonDarkGrey'>
          #31 Southeast Region, 780% 2-Year Growth
        </Text>
      </Flex>
    </Flex>
  );
};

export default IncRanking;
