import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex, Box, Heading, Text } from 'rebass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import StyledIconLink from '../StyledIconLink';

const StoriesCard = ({ story }) => (
  <Flex
    justifyContent='center'
    alignItems={['center', 'center', 'flex-start']}
    mx={['0px', '24px', '24px']}
    mt={['36px', '36px', '24px']}
    mb={['90px', '90px', '170px']}
    flexDirection={['column', 'column', 'row']}>
    <Flex>
      <Box width={['200px', '200px', '400px']}>
        <GatsbyImage image={story.profilePic.gatsbyImageData} alt={story.name} />
      </Box>
    </Flex>
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems={['center', 'center', 'flex-start']}
      m={['12px', '12px', '48px']}
      maxWidth='550px'>
      <Heading fontSize={['32px', '32px', '40px']} fontWeight='500' pb='8px' as='h3'>
        {story.name}
      </Heading>
      <Text fontSize={['20px', '20px', '26px']} color='#9CA5AA' pb='26px'>
        {story.company} - {story.role}
      </Text>
      <Text fontSize={['16px', '16px', '22px']} lineHeight={['20px', '20px', '28px']} pb='28px'>
        {story.testimonial.childMarkdownRemark.rawMarkdownBody}{' '}
      </Text>
      <StyledIconLink href={story.linkedIn} target='_blank' rel='noopener' aria-label='linkedin'>
        <FontAwesomeIcon icon={faLinkedin} />
      </StyledIconLink>
    </Flex>
  </Flex>
);

export default StoriesCard;
