import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from 'rebass';
import StarRating from './StarRating';
import TrustpilotLogo from '../../../assets/images/star-rating/trustpilot-logo.svg';
import GlassdorLogo from '../../../assets/images/star-rating/glassdoor-logo.svg';

const SocialProof = () => {
  const [data, setData] = useState(false);
  const getSocialProofScores = async () => {
    try {
      const response = await fetch(`${process.env.GATSBY_COMPANION_ENDPOINT}`);
      const resultData = await response?.json();
      setData(resultData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!data) {
      getSocialProofScores();
    }
  }, []);
  return (
    <Box>
      <Box mt={['0px', '110px', '120px']} mb='50px' textAlign='center'>
        <Text fontSize='24px' color='nextonDarkGrey' mb='50px' as='h3' fontWeight='400'>
          CHECK OUT OUR <strong>REVIEWS</strong>
        </Text>
      </Box>
      <Flex flexDirection={['column', 'row']}>
        <Box mr={['0px', '50px']} mb={['16px', '0px', '0px']}>
          <StarRating
            titleLogo={TrustpilotLogo}
            value={data?.trustpilot || 4.5}
            link='https://www.trustpilot.com/review/nextonlabs.com'
            addMarginBot
          />
        </Box>
        <StarRating
          titleLogo={GlassdorLogo}
          value={data?.glassdoor || 4.8}
          link='https://www.glassdoor.com/Overview/Working-at-Nexton-EI_IE2903853.11,17.htm'
        />
      </Flex>
    </Box>
  );
};

export default SocialProof;
