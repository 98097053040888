import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';

import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import TalentHero from '../sections/talent/TalentHero';
import Step1 from '../sections/talent/Step1';
import Step2 from '../sections/talent/Step2';
import Step3 from '../sections/talent/Step3';
import Step4 from '../sections/talent/Step4';
import SuccessStories from '../sections/talent/SuccessStories';
import LatestMatches from '../sections/talent/LatestMatches';
import Numbers from '../sections/talent/Numbers';
import Companies from '../sections/talent/Companies';
import LoadableStyledXarrow from '../components/talent/LoadableStyledXarrow';
import IncRanking from '../sections/client/incRanking';
import SocialProof from '../sections/client/SocialProof';

const TalentPage = () => {
  // Make a local query for all talent images and create an object with the results
  const { allFile, latinCircleSm, latinCircleMd, latinCircleLg, latinCircleXl } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/talent" }, name: { ne: "latin-circle" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
          }
          name
        }
      }
      latinCircleSm: file(relativePath: { eq: "images/talent/latin-circle.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 79, height: 79, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
      latinCircleMd: file(relativePath: { eq: "images/talent/latin-circle.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 110, height: 110, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
      latinCircleLg: file(relativePath: { eq: "images/talent/latin-circle.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 205, height: 205, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
      latinCircleXl: file(relativePath: { eq: "images/talent/latin-circle.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 235, height: 235, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);
  const images = {};

  allFile.nodes.forEach(img => {
    images[img.name] = img.childImageSharp.gatsbyImageData;
  });

  const latinCircles = [latinCircleSm, latinCircleMd, latinCircleLg, latinCircleXl];

  const latinCirclesMapped = latinCircles.map(img => ({
    ...img,
    name: `${img.name}-${img.childImageSharp.gatsbyImageData.width}`,
  }));

  latinCirclesMapped.forEach(img => {
    images[img.name] = img.childImageSharp.gatsbyImageData;
  });

  return (
    <>
      <MetaData
        title='Find your dream job'
        description='Our Mission is to connect remote technical team members in LATAM
      with the best global opportunities.'
      />
      <Layout selected='talent' notificationBar='talentBar'>
        <TalentHero />
        <Step1 images={images} />
        <Step2 images={images} />
        <Step3 images={images} />
        <Step4 images={images} />
        <SuccessStories images={images} />
        <Numbers />
        <Companies />
        <LatestMatches />
        <div style={{ zIndex: '-1' }}>
          <LoadableStyledXarrow start='talent-hero' end='latestMatches' />
        </div>
        <Flex flexDirection='column' pb='60px' justifyContent='center' alignItems='center'>
          <Box mb='80px'>
            <SocialProof />
          </Box>
          <IncRanking />
        </Flex>
      </Layout>
    </>
  );
};

export default TalentPage;
