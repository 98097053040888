import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import CirclesKeywords from '../../components/talent/CirclesKeywords';

const TalentHero = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });

  return (
    <>
      <Flex
        // id="talent-hero"
        justifyContent='center'
        alignItems='center'
        style={{ height: 'calc(100vh - 100px)', position: 'relative' }}
        // bg="white"
      >
        <Flex justifyContent='center' alignItems='center' flexWrap='wrap' pb='100px' mt={['50px', 0]} id='talent-hero'>
          <Box
            maxWidth='715px'
            textAlign='center'
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              boxShadow: '0px 0px 35px 20px rgba(255,255,255,0.6)',
              zIndex: 1,
            }}>
            <Heading
              mt={['20px', 4]}
              mb={4}
              fontSize={['48px', '75px']}
              fontWeight='500'
              mx={[2, 'auto']}
              id='talent-hero-heading'
              as='h1'>
              Find your dream job
            </Heading>
            <Text fontSize={['22px', '26px']} px={4} color='greyLight8'>
              Our Mission is to connect remote technical team members in LATAM with the best global opportunities
            </Text>
            <Text
              fontSize='18px'
              maxWidth='370px'
              mt={5}
              mb='22px'
              mx='auto'
              color='greyLight8'
              id='button-start-now-heading'>
              {`Let us know about your career goals and skills and we'll do the rest.`}
            </Text>
            <GatsbyLink to='/join-us'>
              <Button variant='newMain' id='button-start-now'>
                START NOW
              </Button>
            </GatsbyLink>
          </Box>
        </Flex>
        {isDesktopOrLaptop ? <CirclesKeywords /> : <div />}
      </Flex>
    </>
  );
};

export default TalentHero;
