import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import NumbersRising from '../../components/common/NumbersRising';

const Numbers = () => (
  <Box id='numbers' py='50px' my='100px' bg='white'>
    <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap'>
      <Heading mt={3} fontSize={['36px', '40px']} fontWeight='500' letterSpacing='-1px' mb='46px' as='h3'>
        We've helped so far
      </Heading>
      <NumbersRising />
    </Flex>
  </Box>
);

export default Numbers;
