import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Text, Box } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import ReactTooltip from 'react-tooltip';
import LoadableStyledXarrow from './LoadableStyledXarrow';

const XarrowPoint = styled.div`
  position: absolute;
`;

const Circle = ({ id, size, top, left, bg, tooltip, text }) => (
  <Flex
    style={{
      position: 'absolute',
      top: `${top}`,
      left: `${left}`,
      zIndex: '1',
    }}>
    <Fade duration={Math.random() * (1750 - 750) + 750} delay={Math.random() * (1150 - 250) + 250}>
      <Flex
        data-tip={tooltip}
        backgroundColor={bg}
        width={size}
        height={size}
        style={{
          cursor: 'pointer',
          borderRadius: '50%',
        }}
        justifyContent='center'
        alignItems='center'>
        <Box color='backgroundDark' fontWeight='500' textAlign='center' fontSize='30px'>
          <Text>{text}</Text>
        </Box>
        <XarrowPoint id={id} />
      </Flex>
    </Fade>
  </Flex>
);

const RandomXarrow = ({ start, end, renderXarrows }) => (
  <Fade mountOnEnter when={renderXarrows} duration={0} delay={Math.random() * (750 - 250) + 250}>
    <LoadableStyledXarrow start={start} end={end} />
  </Fade>
);

const CirclesKeywords = () => {
  const [renderXarrows, setRenderXarrows] = useState(false);

  const callArrows = () => setRenderXarrows(true);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}>
        <Circle
          id='innovative'
          size='216px'
          top='8%'
          left='3%'
          bg='yellow'
          tooltip='Full of purpose and innovative culture. Work for Health, Fintech, Pharmacy, Communications, Aerospace, you name it!'
          text='Innovative Companies in the US'
        />
        <Circle
          id='salary'
          size='135px'
          top='44%'
          left='9%'
          bg='primary'
          tooltip='There are many considerations when choosing your career focus, but the salary is certainly a key factor when planning your future.'
          text={<Text color='white'>USD Salary</Text>}
        />
        <Circle
          id='contract'
          size='180px'
          top='65%'
          left='-1%'
          bg='greyGreenLight'
          tooltip='We focus on direct contract - long term opportunities.'
          text='Direct Contract'
        />
        <Circle
          id='love'
          size='216px'
          top='60%'
          left='25%'
          bg='#F4F8F9'
          tooltip='The best career opportunities are out there for you, no matter in which country you live in.'
          text={
            <>
              <Fade duration={0} wait={2500} onReveal={callArrows}>
                <FontAwesomeIcon icon={faHeart} size='2x' color='#FF6D00' />
              </Fade>
              <Text color='backgroundDark'>Do what you love</Text>
            </>
          }
        />
        <Circle
          id='remote'
          size='159px'
          top='17%'
          left='84%'
          bg='#F4F8F9'
          tooltip='Our goal is to help top LATAM engineering talent to work with exciting US companies, at the same timezones.'
          text='100% Remote'
        />
        <Circle
          id='long-term'
          size='140px'
          top='44%'
          left='76%'
          bg='greyGreenLight'
          tooltip='We offer long-term positions. We want you to feel the stability and be part fo the team.'
          text='Long Term'
        />
        <Circle
          id='balance'
          size='222px'
          top='58%'
          left='84%'
          bg='vividOrange'
          tooltip='Forget about losing your time commuting to work. Gain time for your family, your friends and have a healthier lifestyle!'
          text={
            <>
              <Text>Work-Life Balance</Text>
              <hr
                style={{
                  backgroundColor: 'white',
                  border: '1.5px solid white',
                  width: '36px',
                  margin: '8px auto',
                }}
              />
              <Text fontSize='17px' fontWeight='400'>
                Family · Sports Relax · Leisure Art
              </Text>
            </>
          }
        />

        <RandomXarrow start='innovative' end='salary' renderXarrows={renderXarrows} />
        <RandomXarrow start='contract' end='salary' renderXarrows={renderXarrows} />
        <RandomXarrow start='contract' end='innovative' renderXarrows={renderXarrows} />
        <RandomXarrow start='contract' end='love' renderXarrows={renderXarrows} />
        <RandomXarrow start='love' end='innovative' renderXarrows={renderXarrows} />
        <RandomXarrow start='love' end='salary' renderXarrows={renderXarrows} />
        <RandomXarrow start='love' end='balance' renderXarrows={renderXarrows} />
        <RandomXarrow start='remote' end='innovative' renderXarrows={renderXarrows} />
        <RandomXarrow start='remote' end='love' renderXarrows={renderXarrows} />
        <RandomXarrow start='long-term' end='innovative' renderXarrows={renderXarrows} />
        <RandomXarrow start='long-term' end='remote' renderXarrows={renderXarrows} />
        <RandomXarrow start='long-term' end='balance' renderXarrows={renderXarrows} />
        <RandomXarrow start='balance' end='remote' renderXarrows={renderXarrows} />
      </div>
      <ReactTooltip border borderColor='#d2d2d2' type='light' className='circlesTooltip' />
    </>
  );
};

export default CirclesKeywords;
