import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass';

import Slider from 'react-slick';
import StoriesCard from '../../components/talent/StoriesCard';

const SuccessStories = () => {
  const stories = useStaticQuery(graphql`
    {
      allContentfulSuccessStory(sort: { fields: createdAt }) {
        nodes {
          name
          company
          role
          linkedIn
          testimonial {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          profilePic {
            title
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  `);

  const settings = {
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1500,
    lazyLoad: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    customPaging: i => (
      <GatsbyImage
        image={stories.allContentfulSuccessStory.nodes[i].profilePic.gatsbyImageData}
        alt={stories.allContentfulSuccessStory.nodes[i].name}
      />
    ),
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    arrows: false,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          dotsClass: 'slick-dots slick-thumb-mobile',
          customPaging: () => <span>dot</span>,
        },
      },
    ],
  };

  return (
    <Box id='successStories' py='50px' my='100px' bg='white'>
      <Slider {...settings}>
        {stories.allContentfulSuccessStory.nodes.map(story => (
          <StoriesCard story={story} key={story.name} />
        ))}
      </Slider>
    </Box>
  );
};

export default SuccessStories;
