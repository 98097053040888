import React from 'react';
import styled from 'styled-components';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import { useMediaQuery } from 'react-responsive';

const MatchCard = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 13px;
  margin: 46px auto 64px auto;
  padding: 30px;
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.16);

  @media (max-width: 1330px) {
    margin-bottom: 72px;
  }
`;

const Pill = styled.div`
  color: ${({ theme, color }) => theme.colors[color] || color || '#3B4B53'};
  background-color: ${({ theme, color }) => theme.colors[color] || color}15;
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  margin: 5px 0px;
  margin: ${({ color }) => (color ? '6px' : '1px')} 0px;
  padding: 5px 16px;

  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

const LatestMatches = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1330px)',
  });

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    pauseOnFocus: true,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 2,
          dots: true,
          dotsClass: 'slick-dots slick-thumb-mobile',
          customPaging: () => <span>dot</span>,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          dots: true,
          dotsClass: 'slick-dots slick-thumb-mobile',
          customPaging: () => <span>dot</span>,
        },
      },
    ],
  };

  return (
    <Box id='latestMatches' py='50px' my='100px' bg='white'>
      <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap'>
        <Box textAlign='center' width='100%'>
          <Heading mt={3} mb={['28px', '42px']} fontSize={['36px', '40px']} fontWeight='500' as='h3'>
            Nextoners latest matches
          </Heading>
          <Box bg='secondaryLight2' mx='-20px' px='20px'>
            <Slider {...settings}>
              <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 300 : 0}>
                <MatchCard maxWidth='410px'>
                  <Flex flexDirection='column' alignItems='flex-start'>
                    <Text fontSize={['28px', '34px']} fontWeight='500' mb='6px'>
                      Sr. Data Engineer
                    </Text>
                    <Text fontSize={['24px', '28px']} mb='12px'>
                      US Fintech Startup
                    </Text>
                    <Text fontSize='16px' fontWeight='500' mb='28px'>
                      Funding $32 million
                    </Text>
                    <Pill color='primary'>💰 $7,000 - $10,000 USD/Mo + EQY</Pill>
                    <Pill color='secondary'>🌎 100% Remote (EST Time Zone)</Pill>
                    <Pill color='#3B4B53'>📃 Direct Hire, Long-term</Pill>
                    <Pill>🛠 Scala, Spark, AWS</Pill>
                    <Pill>📌 LATAM</Pill>
                  </Flex>
                </MatchCard>
              </Fade>
              <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 50 : 0}>
                <MatchCard maxWidth='410px'>
                  <Flex flexDirection='column' alignItems='flex-start'>
                    <Text fontSize={['28px', '34px']} fontWeight='500' mb='6px'>
                      Full Stack Engineer
                    </Text>
                    <Text fontSize={['24px', '28px']} mb='12px'>
                      US Big Data Startup
                    </Text>
                    <Text fontSize='16px' fontWeight='500' mb='28px'>
                      Funding $75 million
                    </Text>
                    <Pill color='primary'>💰 $8,000 - $10,000 USD/Mo + EQY</Pill>
                    <Pill color='secondary'>🌎 100% Remote (EST Time Zone)</Pill>
                    <Pill color='#3B4B53'>📃 Direct Hire, Long-term</Pill>
                    <Pill>🛠 Python, React, AWS</Pill>
                    <Pill>📌 LATAM</Pill>
                  </Flex>
                </MatchCard>
              </Fade>
              <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 500 : 0}>
                <MatchCard maxWidth='410px'>
                  <Flex flexDirection='column' alignItems='flex-start'>
                    <Text fontSize={['28px', '34px']} fontWeight='500' mb='6px'>
                      Sr. FrontEnd Dev
                    </Text>
                    <Text fontSize={['24px', '28px']} mb='12px'>
                      US Insurance Startup
                    </Text>
                    <Text fontSize='16px' fontWeight='500' mb='28px'>
                      Funding $110 million
                    </Text>
                    <Pill color='primary'>💰 $6,000 - $8,500 USD/Mo + EQY</Pill>
                    <Pill color='secondary'>🌎 100% Remote (EST Time Zone)</Pill>
                    <Pill color='#3B4B53'>📃 Direct Hire, Long-term</Pill>
                    <Pill>🛠 React, Node, Javascript</Pill>
                    <Pill>📌 LATAM</Pill>
                  </Flex>
                </MatchCard>
              </Fade>
            </Slider>
          </Box>

          <Text fontSize={['18px', '20px']} color='greyLight8' my={4}>
            Want to find your dream job? Tell us about your skills, interests and career goals and we’ll find the ideal
            offer for you.
          </Text>
          <GatsbyLink to='/join-us'>
            <Button variant='newMain'>FIND YOUR DREAM JOB</Button>
          </GatsbyLink>
        </Box>
      </Flex>
    </Box>
  );
};

export default LatestMatches;
