import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex, Box, Heading, Text, Button, Image } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import Fade from 'react-reveal/Fade';
import HeadShake from 'react-reveal/HeadShake';
import Zoom from 'react-reveal/Zoom';
import nextonIcon from '../../../assets/images/logo/icon-89.svg';

const ZoomedLogo = () => (
  <Flex
    justifyContent='center'
    alignItems='center'
    pt={[0, '13px']}
    style={{
      position: 'absolute',
      top: '94px',
      zIndex: '2',
    }}>
    <Zoom delay={1050}>
      <Flex
        alignItems='center'
        justifyContent='center'
        bg='secondary'
        style={{ borderRadius: '100%' }}
        width={['100px', '155px']}
        height={['100px', '155px']}
        pt={['10px', '12px']}>
        <Box maxWidth={['55%', '50%']}>
          <Image src={nextonIcon} alt='Nexton Icon' />
        </Box>
      </Flex>
    </Zoom>
  </Flex>
);

const Step4 = ({ images }) => (
  <Box style={{ position: 'relative' }} my='100px'>
    <Box style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '0' }}>
      <Zoom delay={1050}>
        <GatsbyImage
          image={images['confetti-big']}
          alt='confetti big'
          style={{ height: '100vh', marginTop: '-24px' }}
        />
      </Zoom>
    </Box>
    <Box id='step4' py='50px' my='100px' bg='white'>
      <Flex alignItems='center' flexDirection='column'>
        <Box maxWidth='578px' textAlign='center'>
          <Flex justifyContent='center'>
            <Text fontSize='20px' fontWeight='500' color='secondaryDark'>
              STEP 4
            </Text>
          </Flex>
          <Heading mt={3} fontSize={['36px', '40px']} fontWeight='500' mb={4} as='h2'>
            It's a match!
          </Heading>
          <Box style={{ position: 'relative' }}>
            <Box style={{ position: 'absolute', width: '100%', height: '100%' }}>
              <Zoom delay={1050}>
                <GatsbyImage image={images['confetti-small']} alt='confetti small' style={{ height: '100%' }} />
              </Zoom>
            </Box>

            <Flex py={5} justifyContent='center' flexWrap='wrap'>
              <Fade left delay={250}>
                <HeadShake delay={750}>
                  <Box width={['160px', '235px']}>
                    <GatsbyImage image={images['latin-circle-235']} alt='talent pic' />
                  </Box>
                </HeadShake>
              </Fade>
              <ZoomedLogo />
              <Fade right delay={250}>
                <HeadShake delay={750}>
                  <Box width={['160px', '235px']}>
                    <GatsbyImage
                      image={images.bitcoin}
                      alt='Bitcoin'
                      style={{
                        borderRadius: '100%',
                      }}
                    />
                  </Box>
                </HeadShake>
              </Fade>
            </Flex>

            <Text
              fontSize='20px'
              color='greyLight8'
              mt={2}
              style={{
                position: 'relative',
                textShadow: '0px 0px 5px rgb(255 255 255), 0px 0px 5px rgb(255 255 255), 0px 0px 5px rgb(255 255 255)',
              }}>
              Here is where you grow and you achieve your life goals. You can trust Nexton as your life-long career
              partner.
            </Text>
          </Box>
        </Box>
        <GatsbyLink to='/join-us' style={{ zIndex: 1 }}>
          <Button variant='newMain' bg='secondary' mt='92px'>
            START GROWING YOUR CAREER
          </Button>
        </GatsbyLink>
      </Flex>
    </Box>
  </Box>
);

export default Step4;
