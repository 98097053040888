import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 26px;
  margin: 46px auto 64px auto;
  padding: 30px;
  box-shadow: 0 41px 60px 11px rgba(161, 161, 161, 0.16);
`;

const StepCard = ({ maxWidth, children }) => <Container maxWidth={maxWidth}>{children}</Container>;

export default StepCard;
