import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Flex, Box, Heading, Text } from 'rebass';

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import HeadShake from 'react-reveal/HeadShake';

import StepCard from '../../components/talent/StepCard';

const CheckMark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  width: 8px;
  height: 16px;
  border-bottom: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
`;

const Step1 = ({ images }) => (
  <Box id='step1' py='50px' my='100px' bg='white'>
    <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap'>
      <Box maxWidth='715px' textAlign='center'>
        <Flex justifyContent='center'>
          <Text fontSize='20px' fontWeight='500' mr={3} color='secondaryDark'>
            STEP 1
          </Text>
          <Text fontSize='20px' color='greyLight5'>
            4 min
          </Text>
        </Flex>

        <Heading mt={3} fontSize={['36px', '40px']} fontWeight='500' as='h2'>
          Tell us about you
        </Heading>
        <StepCard maxWidth='495px'>
          <Flex flexDirection='column'>
            <Flex>
              <Box minWidth='79px'>
                <Fade delay={250} duration={1000}>
                  <GatsbyImage image={images['latin-circle-79']} alt='talent pic' />
                </Fade>
              </Box>
              <Box width='100%'>
                <Fade delay={650}>
                  <Box
                    style={{
                      height: '20px',
                      width: '50%',
                      borderRadius: '14px',
                      backgroundColor: '#00A5C8',
                      margin: '6px 0px 0px 15px',
                    }}
                  />
                </Fade>
                <Fade delay={900}>
                  <Box
                    style={{
                      height: '16px',
                      width: '80%',
                      borderRadius: '14px',
                      backgroundColor: '#00A5C8',
                      opacity: '0.2',
                      margin: '14px 0px 0px 15px',
                    }}
                  />
                </Fade>
              </Box>
            </Flex>
            <Box
              style={{
                height: '65px',
                maxWidth: '430px',
                border: '1px solid rgba(231,231,231,0.46)',
                borderRadius: '8px',
                padding: '16px',
                boxShadow: 'inset 0 -2px 4px 0 rgba(190,190,190,0.27)',
                marginTop: '14px',
              }}>
              <Flex alignItems='center'>
                <Box
                  style={{
                    height: '30px',
                    width: '30px',
                    minWidth: '30px',
                    borderRadius: '6px',
                    backgroundColor: 'rgba(102,199,135,0.64)',
                  }}>
                  <Flex justifyContent='center' alignItems='center' height='100%'>
                    <Box>
                      <Bounce delay={1400}>
                        <CheckMark />
                      </Bounce>
                    </Box>
                  </Flex>
                </Box>
                <Box width='100%'>
                  <HeadShake delay={1550}>
                    <Box
                      style={{
                        height: '15px',
                        width: '30%',
                        borderRadius: '8px',
                        backgroundColor: '#A4A4A4',
                        opacity: '0.16',
                        marginLeft: '18px',
                      }}
                    />
                  </HeadShake>
                </Box>
              </Flex>
            </Box>
            <Box
              style={{
                height: '65px',
                maxWidth: '430px',
                border: '1px solid rgba(231,231,231,0.46)',
                borderRadius: '8px',
                padding: '16px',
                boxShadow: 'inset 0 -2px 4px 0 rgba(190,190,190,0.27)',
                marginTop: '14px',
              }}>
              <Flex alignItems='center'>
                <Box
                  style={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '6px',
                    backgroundColor: 'rgba(102,199,135,0.64)',
                  }}>
                  <Flex justifyContent='center' alignItems='center' height='100%'>
                    <Box>
                      <Bounce delay={1700}>
                        <CheckMark />
                      </Bounce>
                    </Box>
                  </Flex>
                </Box>
                <HeadShake delay={1850}>
                  <Box
                    style={{
                      height: '15px',
                      width: '160px',
                      borderRadius: '8px',
                      backgroundColor: '#A4A4A4',
                      opacity: '0.16',
                      marginLeft: '18px',
                    }}
                  />
                </HeadShake>
              </Flex>
            </Box>
          </Flex>
        </StepCard>
        <Text fontSize='22px' color='greyLight8'>
          We are happy to meet you! <br />
          Let us know about your skills, interests and career goals.
        </Text>
      </Box>
    </Flex>
  </Box>
);

export default Step1;
