import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import CompaniesLogos from '../../components/CompaniesLogos';

const Companies = () => (
  <Box id='companies' py='50px' my='100px' bg='white'>
    <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap'>
      <Box maxWidth='1200px' textAlign='center'>
        <Heading maxWidth='790px' mx='auto' mt={3} fontSize={['36px', '40px']} fontWeight='500' mb='25px' as='h3'>
          Get connected to 200+ exciting companies from US
        </Heading>
        <Text fontSize='20px' color='greyLight6' textAlign='center' maxWidth='622px' mx='auto' pb={3}>
          Purpose driven, VC backed, high performance tech startups taking the lead in decentralized work.
        </Text>
        <CompaniesLogos />
      </Box>
    </Flex>
  </Box>
);

export default Companies;
