import React from 'react';
import { Flex, Box } from 'rebass';
import Fade from 'react-reveal/Fade';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

const ImageContainer = ({ src, alt }) => (
  <Flex alignItems='center' justifyContent='center' width={['50%', 1 / 3, '25%']} px='25px' pb='50px'>
    <Fade duration={Math.random() * 2500} delay={Math.random() * 900}>
      <Box maxWidth='335px'>
        <GatsbyImage image={src} alt={alt} />
      </Box>
    </Fade>
  </Flex>
);

const CompaniesLogos = () => {
  // Make a local query for all Companies images and create an array with the results
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/companies-logos" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
      }
    }
  `);
  const companiesImages = [];
  allFile.nodes.forEach(img => {
    companiesImages.push({ name: img.name, ...img.childImageSharp.gatsbyImageData });
  });

  return (
    <Flex justifyContent='center' flexWrap='wrap' my={3}>
      {companiesImages.map(image => (
        <ImageContainer src={image} alt={image.name} key={image.name} />
      ))}
    </Flex>
  );
};

export default CompaniesLogos;
